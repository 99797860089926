var site = site || {};

site.userInfoCookie = site.userInfoCookie || {};
(function ($) {
  Drupal.behaviors.headerOffersBannerFormatterV1 = {
    attached: false,
    attach: function (context) {
      var self = this;

      if (self.attached) {
        return;
      }

      var $formatter = $('.js-header-offers-banner-formatter-v1', context);
      var $carousel = $('.js-header-offers-banner-formatter-carousel', $formatter);
      var $slides = $carousel.find('.header-offers-banner-item').not('.hidden');
      var $close = $('.js-header-offers-banner-formatter__close', $formatter);
      var $arrow = $('.js-header-offers-banner-formatter-carousel-arrow', $carousel);
      var $loyalty = $('.js-site-header-formatter__utilities--loyalty', context);
      var $offerText = $('.js-header-offers-banner-item__offer-text', $formatter);
      var slideIndex = 1;
      var timer = null;
      var autoplay = $carousel.data('slides-autoplay');
      var speed = parseInt($carousel.data('speed'));
      var $offersWrapper = $('.js-site-header-formatter__offers', context);
      var isCr21 =
        Drupal.settings.globals_variables && Drupal.settings.globals_variables.cr21 ? true : false;

      if (
        window.socialshoppe &&
        site.templates &&
        site.templates.elc_social_shoppe_v1 &&
        site.templates.elc_social_shoppe_v1.data
      ) {
        displayBannerBasedOnSocialShoppe();

        return;
      }

      displayOfferBasedOnUserType();
      showSlides(slideIndex);

      $(document).on('user.loaded', function () {
        displayOfferBasedOnUserType();
      });

      if ($.cookie('hide_header_offer_banner')) {
        $('body').addClass('gnav-offers-hidden');

        return;
      }

      Unison.on('change', function () {
        if (isCr21) {
          updateCarouselWidth();
          scrollOfferText($slides);
        }
        if (!self.attached && isCr21) {
          makeOfferTextClickable();
        }
      });

      $formatter.addClass('offers-banner-initialized');

      // Next/previous controls
      $arrow.once().on('click', function () {
        var newIndex = $(this).data('index');

        clearTimeout(timer);
        showSlides((slideIndex += newIndex));
      });

      $close.once().on('click', function () {
        $.cookie('hide_header_offer_banner', '1', {
          path: '/',
          expires: 60
        });
        $(document).trigger('hide_header_offer_banner');
        $('body').addClass('gnav-offers-hidden');
      });

      function updateCarouselWidth() {
        var carouselMargin = 25;

        // Adjust carousel's width depending on loyalty
        if ($loyalty && $loyalty.length > 0 && $loyalty.is(':visible')) {
          $carousel.css({
            width: $offersWrapper.width() - $loyalty.width() - carouselMargin + 'px'
          });
        } else {
          $carousel.css({
            width: $offersWrapper.width()
          });
        }
      }

      function scrollOfferText($currentSlide) {
        var carouselWidth = $carousel.width();
        var $itemOfferClone;

        // If the method gets called on unison.change, reset currentSlide
        if ($currentSlide && $currentSlide.length > 1) {
          $currentSlide = $currentSlide.filter(':visible');
        }

        var $itemOfferText =
          $currentSlide &&
          $currentSlide.find('.js-header-offers-banner-item__offer-text.mobile-hidden')
            ? $currentSlide.find('.js-header-offers-banner-item__offer-text.mobile-hidden')
            : null;
        var itemOfferWidth = $itemOfferText.width();

        if (itemOfferWidth > carouselWidth) {
          $itemOfferClone = $itemOfferText.clone();
          $itemOfferText.length === 1 ? $itemOfferClone.insertAfter($itemOfferText) : null;
          $itemOfferText = $currentSlide.find('.js-header-offers-banner-item__offer-text');
          $itemOfferText.addClass('offer-text-scroll');
          $currentSlide.removeClass('offer-center-aligned');
        } else {
          $offerText = $currentSlide.find(
            '.js-header-offers-banner-item__offer-text.mobile-hidden'
          );
          $itemOfferClone =
            $offerText && $offerText.length > 1 ? $offerText.slice(1).remove() : null;
          $offerText.removeClass('offer-text-scroll');
          $itemOfferClone && $itemOfferClone.length > 0 ? $itemOfferClone.remove() : null;
          $currentSlide.addClass('offer-center-aligned');
        }
      }

      function makeOfferTextClickable() {
        $offerText = $carousel.find('.js-header-offers-banner-item__offer-text.mobile-hidden');

        var linkHref;

        $offerText && $offerText.length > 0
          ? $offerText.each(function () {
              var $this = $(this);
              var $existingLink = $this.find('a').not('.wrapper-link');

              if ($this.find('.wrapper-link').length === 0) {
                linkHref = $existingLink.attr('href');
                $this.children().wrap(function () {
                  return "<a href='" + linkHref + "' class='wrapper-link'></a>";
                });
              }
              checkIfTabletPortrait()
                ? $existingLink.addClass('hidden')
                : $existingLink.removeClass('hidden');
            })
          : null;
      }

      function showSlides(n) {
        if (n === undefined) {
          n = ++slideIndex;
        }
        if (n > $slides.length) {
          slideIndex = 1;
        }
        if (n < 1) {
          slideIndex = $slides.length;
        }
        $slides.removeClass('item-active');
        $currentSlide = $slides.eq(slideIndex - 1);
        $currentSlide.addClass('item-active');
        if (isCr21) {
          updateCarouselWidth();
          scrollOfferText($currentSlide);
          makeOfferTextClickable();
        }
        if (autoplay) {
          timer = setTimeout(showSlides, speed);
        }
      }

      function displayOfferBasedOnUserType() {
        var signedIn = parseInt(site.userInfoCookie.getValue('signed_in'));
        var is_mac_pro_member = $.cookie('MACUSERTYPE') === 'PRO' ? 1 : 0;
        var is_loyalty_member = parseInt(site.userInfoCookie.getValue('is_loyalty_member')) ? 1 : 0;
        var loyaltyLevel = parseInt(site.userInfoCookie.getValue('loyalty_level'));

        $carousel.find('.header-offers-banner-item').addClass('hidden');
        if (is_mac_pro_member && signedIn) {
          $carousel.find('.header-offers-banner-item.pro-user-offer').removeClass('hidden');
          $slides = $carousel.find('.header-offers-banner-item').not('.hidden');
        } else if (is_loyalty_member && loyaltyLevel && signedIn) {
          $carousel
            .find('.header-offers-banner-item.' + 'level' + loyaltyLevel)
            .removeClass('hidden');
          $slides = $carousel.find('.header-offers-banner-item').not('.hidden');
        } else {
          $carousel
            .find('.header-offers-banner-item')
            .not('.pro-user-offer, .loyalty-tier-offer')
            .removeClass('hidden');
        }
      }

      function handleConsentDecision(currentDesc) {
        const hasConsentAccepted = currentDesc?.includes('4');
        const $headerOfferItem = $carousel
          .find('#extole_zone_global_header')
          .parents('.header-offers-banner-item');

        if (!hasConsentAccepted) {
          $headerOfferItem.addClass('hidden');
        } else {
          $headerOfferItem.removeClass('hidden');
        }

        $slides = $carousel.find('.header-offers-banner-item').not('.hidden');
        clearTimeout(timer);
        showSlides(slideIndex);
      }

      function waitForTealiumConsent(attempts = 0) {
        const MAXRETRIES = 10;
        const INTERVAL = 2000;
        const currentDesc = window.tealiumConsentRegister?.currentDecision;

        if (currentDesc !== undefined) {
          handleConsentDecision(currentDesc);
        } else if (attempts < MAXRETRIES) {
          setTimeout(() => {
            waitForTealiumConsent(attempts + 1);
          }, INTERVAL);
        }
      }

      /* 'window.tealiumConsentRegister' is undefined during initial page load.
         this method uses a setTimeout mechanism to periodically check for the value
         and process it once available. */
      waitForTealiumConsent();

      function displayBannerBasedOnSocialShoppe() {
        if ($('body').hasClass('social-shoppe-enabled')) {
          return false;
        }
        const parsedSocialShoppeCookie = generic.cookie('socialshoppe_session');
        const template = site.templates.elc_social_shoppe_v1.content;
        const data = site.templates.elc_social_shoppe_v1.data;
        const rendered = Mustache.render(template, {
          ...data,
          ...parsedSocialShoppeCookie
        });

        $('body').addClass('social-shoppe-enabled');
        $formatter.closest('.js-site-header-formatter-inner').prepend(rendered);
        $formatter.addClass('hidden');
      }

      function checkIfTabletPortrait() {
        if (Unison.fetch.now()) {
          var currentBpWidth = parseInt(Unison.fetch.now().width, 10);
          var bpMediumUp = parseInt(Unison.fetch.all()['usn-medium'], 10);
          var bpLargestUp = parseInt(Unison.fetch.all()['usn-largest'], 10);
          var isTabletPortrait = currentBpWidth <= bpLargestUp && currentBpWidth >= bpMediumUp;
          var hasMouse =
            matchMedia('(pointer:fine)').matches || $('html').hasClass('no-touch') || false;

          return isTabletPortrait && !hasMouse;
        }
      }
      const urlParams = new URLSearchParams(window.location.search);
      const hideGNavParam = urlParams.get(Drupal.settings.hideGNav.param);
      const pathName = window.location.pathname;
      const hideGNavPath = Drupal.settings.hideGNav.path;

      if (hideGNavParam && pathName.includes(hideGNavPath)) {
        $offersWrapper.addClass('hidden');
        $loyalty.addClass('hidden');
      } else {
        $offersWrapper.removeClass('hidden');
        $loyalty.removeClass('hidden');
      }

      self.attached = true;
    }
  };
})(jQuery);
